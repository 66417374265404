
<template>
    <div>

        <b-modal ref="selectPopup" centered hide-header hide-footer>

            <div class="mt-2">
                <input-select :label="textoLabel" :opcoes="opcoes" v-model="opcaoSelecionada" />
                <p style="color:red; font-size:13px;" v-if="opcaoInvalida">É necessario selecionar uma opção.</p>
            </div>

            <div class="w-100 text-right mt-4">
                <botao-modal-cancel class="d-inline mr-2" @click="fecharPopup">Cancelar</botao-modal-cancel>
                <botao-modal-ok class="d-inline" @click="enviarDadoSelecionado">Salvar</botao-modal-ok>
            </div>

        </b-modal>

    </div>
</template>

<script>
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'
    import InputSelect from '@/components/inputs/InputSelect.vue'

    export default {
        name: 'InputSelectPopup',
        components: {
            InputSelect,
            BotaoModalOk,
            BotaoModalCancel
        },
        data: function () {
            return {
                tipoSelect: {},
                textoLabel: '',
                opcoes: [],
                opcaoSelecionada: '',

                opcaoInvalida: false,
            }
        },
        methods: {
            setDadosSelect: function (dados) {
               

                this.limparSelect();

                this.tipoSelect = dados.infoSelect;
                this.textoLabel = dados.label;
                this.opcoes = dados.opcoes;
                this.opcaoSelecionada = dados.opcaoSelecionada ? dados.opcaoSelecionada : '';
            },
            abrir: function () {
                this.$refs.selectPopup.show();
            },
            enviarDadoSelecionado: function () {
                if (!this.opcaoSelecionada) {
                    this.opcaoInvalida = true;
                    return;
                }

                this.$emit('dadoSelecionado', { 'selected': this.opcaoSelecionada, ...this.tipoSelect });
                this.fecharPopup();
            },
            fecharPopup: function () {
                this.limparSelect();
                this.$refs.selectPopup.hide();
            },
            limparSelect: function () {
                this.textoLabel = '';
                this.opcoes = [];
                this.opcaoSelecionada = '';
                this.opcaoInvalida = false;
            }
        },
    }
</script>


<template>


    <b-modal id="NovaConexaoPopup" ref="selectPopup" centered hide-footer :title="`Nova conexão ${tiposNomes[tipo]}`" class="titleModal">

        <b-container>
            <!--#region TIPOS DE MODAIS PARA CADA CANAL: <<===========================================>> -->
            
            <!-->
            <!-->

            <!--#region Backup  popupConexão -->

             <!--<div v-else-if="tipo == '1'" class="whatsapp">-->


                    <!--NOME PARA A  CONEXÃO ------------------------------------>
                    <!--<b-cool style="align-self: flex-end;">

                        <div class="data-field">

                            <label>Nome da conexão</label>
                            <input type="text" />

                        </div>

                    </b-cool>-->


                    <!--NUMERO PARA A  CONEXÃO ------------------------------------>
                    <!--<b-cool>

                        <div class="data-field">

                            <label>Numero para conexão (com DDI e DDD)</label>

                            <input type="text" placeholder="Ex: 551412341234" style="" />

                        </div>

                    </b-cool>


                    <b-cool style="align-self: flex-end;">

                        <div class="data-field">

                            <label>URL Serviço de integração</label>
                            <input type="text" placeholder="http://191.152.178.132"/>

                        </div>

                    </b-cool>-->

                    <!--SELETOR DE SETORES ------------------------------------>
                    <!--<div class="data-field">

                        <label class="titleSetores">Selecione os setores para o canal </label>
                        <div class="setores">

                            <div v-for="option in options">
                                <input type="checkbox" :id="`setor-${option.value}`" :value="option.value" v-model="selected">
                                <label :for="`setor-${ option.value}`">{{ option.text}}</label>

                            </div>

                        </div>
                    </div>-->

                    <!--CAMPOS ESPECIAIS ------------------------------------>

                    <!--<b-row>
                        <b-col class="data-field">
                            <label>Campo especial 1</label>
                            <input class="campoEspecial" type="text" />
                        </b-col>
                        <b-col class="data-field">
                            <label>Campo especial 2</label>
                            <input class="campoEspecial" type="text" />
                        </b-col>
                    </b-row>-->


                    <!--BOTÃO PARA CONFIGURAÇÃO EXTERNA ------------------------------------>

                    <!--<button class="configExternaButtom">Botão para configuração externa</button>


                </div>-->

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO CHATBOT << -------------------- >> -->
                <div v-if="tipo == '0'">
                                        <!--SELETOR DE SETORES ------------------------------------>
                    <b-col class="data-field">

                        <label class="titleSetores">Selecione os setores para o canal </label>
                        <div class="setores">

                            <div v-for="option in options">
                                <input type="checkbox" :id="`setor-${option.value}`" :value="option.value" v-model="selected">
                                <label :for="`setor-${ option.value}`">{{ option.text}}</label>

                            </div>

                        </div>
                    </b-col>
                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO WHATSAPP INFO BIP << -------------------- >> -->

                <div v-else-if="tipo == '1'" class="whatsapp">


                    <!--NOME PARA A  CONEXÃO ------------------------------------>
                    <b-col style="align-self: flex-end;">

                        <div class="data-field">

                            <label>Número Whatsapp (Com DDD e DDI)</label>
                            <input type="text" placeholder="(Número sem espaços ou traços)" />

                        </div>

                    </b-col>


                    <!--SELETOR DE SETORES ------------------------------------>
                    <b-col class="data-field">

                        <label class="titleSetores">Selecione os setores para o canal </label>
                        <div class="setores">

                            <div v-for="option in options">
                                <input type="checkbox" :id="`setor-${option.value}`" :value="option.value" v-model="selected">
                                <label :for="`setor-${ option.value}`">{{ option.text}}</label>

                            </div>

                        </div>
                    </b-col>



                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO EMAIL << -------------------- >> -->

                <div v-else-if="tipo == '2'" class="email">


                    <!--NOME PARA A  CONEXÃO ------------------------------------>


                    <h1 class="tituloDoCampo">Configurações de E-mail (Recebimento)</h1>


                    <!--CAMPOS DE INSERÇÃO ------------------------------------>
                    <!-- 1 --------->
                    <b-row>

                        <b-col class="data-field">

                            <label>Email SAC *</label>

                            <input type="text" />

                        </b-col>

                        <b-col class="data-field">

                            <label>Senha SAC *</label>

                            <input type="text" />

                        </b-col>

                    </b-row>

                    <!-- 2 --------->

                    <b-row>

                        <b-col class="data-field">

                            <label>Endereço IMAP *</label>

                            <input type="text" />

                        </b-col>

                        <b-col class="data-field">

                            <label>Porta IMAP *</label>

                            <input type="text" />

                        </b-col>


                    </b-row>

                    <!-- 3 --------->

                    <b-row>

                        <b-col class="data-field">

                            <label>Habilitar atendimento automático</label>

                            <select>

                                <option value="nao">Não</option>
                                <option value="sim">Sim</option>

                            </select>

                        </b-col>

                        <b-col class="data-field">

                            <label>Esclusão automática de E-mail</label>

                            <select>

                                <option value="nao">Não</option>
                                <option value="sim">Sim</option>

                            </select>

                        </b-col>

                    </b-row>



                    <!-- 4 --------->

                    <h1 class="tituloDoCampo">Configurações de E-mail (Envio)</h1>

                    <b-row>

                        <b-col class="data-field">

                            <label>Email SMTP *</label>

                            <input type="text" />

                        </b-col>

                        <b-col class="data-field">

                            <label>Senha SMTP *</label>

                            <input type="text" />

                        </b-col>

                    </b-row>

                    <!-- 5 --------->

                    <b-row>

                        <b-col class="data-field">

                            <label>Endereço SMTP *</label>

                            <input type="text" />

                        </b-col>

                        <b-col class="data-field">

                            <label>Porta SMPT * ('padrão  587')</label>

                            <input type="text" />

                        </b-col>


                    </b-row>

                    <!-- 6 --------->

                    <b-row>

                        <b-col class="data-field">

                            <label>Habilitar SSL</label>

                            <select>

                                <option value="nao">Não</option>
                                <option value="sim">Sim</option>

                            </select>

                        </b-col>

                        <b-col class="data-field">

                            <label>Habilitar credenciais de E-mail</label>

                            <select>

                                <option value="nao">Não</option>
                                <option value="sim">Sim</option>

                            </select>

                        </b-col>

                    </b-row>


                    <!--SELETOR DE SETORES ------------------------------------>
                    <div class="data-field">

                        <label class="titleSetores">Selecione os setores para o canal </label>
                        <div class="setores">

                            <div v-for="option in options">
                                <input type="checkbox" :id="`setor-${option.value}`" :value="option.value" v-model="selected">
                                <label :for="`setor-${ option.value}`">{{ option.text}}</label>

                            </div>

                        </div>
                    </div>


                    <!--CAMPOS ESPECIAIS ------------------------------------>

                    <b-row>
                        <b-col class="data-field">
                            <label>Campo especial 1</label>
                            <input class="campoEspecial" type="text" />
                        </b-col>
                        <b-col class="data-field">
                            <label>Campo especial 2</label>
                            <input class="campoEspecial" type="text" />
                        </b-col>
                    </b-row>


                    <!--BOTÃO PARA CONFIGURAÇÃO EXTERNA ------------------------------------>

                    <button class="configExternaButtom">Botão para configuração externa</button>


                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO ATENDIMENTO ATIVO << -------------------- >> -->

            <div v-else-if="tipo == '3'" class="whatsapp">


                <!--NOME PARA A  CONEXÃO ------------------------------------>
                <b-cool style="align-self: flex-end;">

                    <div class="data-field">

                        <label>Nome da conexão</label>
                        <input type="text" />

                    </div>

                </b-cool>


                <!--NUMERO PARA A  CONEXÃO ------------------------------------>
                <b-cool>

                    <div class="data-field">

                        <label>Numero para conexão (com DDI e DDD)</label>

                        <input type="text" />

                    </div>

                </b-cool>


                <!--SELETOR DE SETORES ------------------------------------>
                <div class="data-field">

                    <label class="titleSetores">Selecione os setores para o canal </label>
                    <div class="setores">

                        <div v-for="option in options">
                            <input type="checkbox" :id="`setor-${option.value}`" :value="option.value" v-model="selected">
                            <label :for="`setor-${ option.value}`">{{ option.text}}</label>

                        </div>

                    </div>
                </div>


                <!--CAMPOS ESPECIAIS ------------------------------------>

                <div class="specialLabelContainer">

                    <span class="specialLabel" style="margin-right: 65px">Campo especial 1</span>
                    <span class="specialLabel" style="margin-left: 65px">Campo especial 2</span>

                </div>


                <div class="data-field">

                    <input class="campoEspecial" type="text" style="margin-right: 12px; " />
                    <input class="campoEspecial" type="text" style="margin-left: 12px; " />

                </div>


                <!--BOTÃO PARA CONFIGURAÇÃO EXTERNA ------------------------------------>

                <button class="configExternaButtom">Botão para configuração externa</button>


            </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO FACEBOOK << -------------------- >> -->

                <div v-else-if="tipo == '4'" class="whatsapp">
                    <b-col style="align-self: flex-end;">

                        <div class="data-field">

                            <label>Código da página</label>
                            <input type="text" />

                        </div>

                    </b-col>


                    <!--SELETOR DE SETORES ------------------------------------>
                    <b-col class="data-field">

                        <label class="titleSetores">Selecione os setores para o canal </label>
                        <div class="setores">

                            <div v-for="option in options">
                                <input type="checkbox" :id="`setor-${option.value}`" :value="option.value" v-model="selected">
                                <label :for="`setor-${ option.value}`">{{ option.text}}</label>

                            </div>

                        </div>
                    </b-col>
                </div>


            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO SMS << -------------------- >> -->
                <div v-if="tipo == '5'">

                    <span> conexão chatbot </span>

                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO MAILING << -------------------- >> -->
                <div v-if="tipo == '6'">

                    <span> conexão chatbot </span>

                </div>

            <!--#endregion -->
            <!-->
            <!-->


            <!--#region TELEFONIA RECEPTIVA << -------------------- >> -->
                <div v-if="tipo == '7'">

                    <span> conexão chatbot </span>

                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO TELEGRAM << -------------------- >> -->
                <div v-if="tipo == '8'">

                    <span> conexão chatbot </span>

                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO WEBWHATS << -------------------- >> -->
                <div v-if="tipo == '9'">


                    <!--NOME PARA A  CONEXÃO ------------------------------------>
                    <b-col style="align-self: flex-end;">

                        <div class="data-field">

                            <label>Número Whatsapp (Com DDD e DDI)</label>
                            <input type="text" placeholder="(Número sem espaços ou traços)" />

                        </div>

                    </b-col>


                    <!--SELETOR DE SETORES ------------------------------------>
                    <b-col class="data-field">

                        <label class="titleSetores">Selecione os setores para o canal </label>
                        <div class="setores">

                            <div v-for="option in options">
                                <input type="checkbox" :id="`setor-${option.value}`" :value="option.value" v-model="selected">
                                <label :for="`setor-${ option.value}`">{{ option.text}}</label>

                            </div>

                        </div>
                    </b-col>



                </div>
            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO INSTAGRAM << -------------------- >> -->
                <div v-if="tipo == '10'">


                    <b-col style="align-self: flex-end;">

                        <div class="data-field">

                            <label>Código da página</label>
                            <input type="text" />

                        </div>

                    </b-col>


                    <!--SELETOR DE SETORES ------------------------------------>
                    <b-col class="data-field">

                        <label class="titleSetores">Selecione os setores para o canal </label>
                        <div class="setores">

                            <div v-for="option in options">
                                <input type="checkbox" :id="`setor-${option.value}`" :value="option.value" v-model="selected">
                                <label :for="`setor-${ option.value}`">{{ option.text}}</label>

                            </div>

                        </div>
                    </b-col>
                </div>
            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO RCS-REMOVIDO << -------------------- >> -->
                <div v-if="tipo == '11'">

                    <span> conexão chatbot </span>

                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO ML-PERGUNTA << -------------------- >> -->
                <div v-if="tipo == '12'">

                    <span> conexão chatbot </span>

                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO ML-MENSAGEM << -------------------- >> -->
                <div v-if="tipo == '13'">

                    <span> conexão chatbot </span>

                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO SHOPEE << -------------------- >> -->
                <div v-if="tipo == '7'">

                    <span> conexão chatbot </span>

                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO WHATSAPP BUSINESS << -------------------- >> -->
                <div v-if="tipo == '15'">


                    <!--NOME PARA A  CONEXÃO ------------------------------------>
                    <b-col style="align-self: flex-end;">

                        <div class="data-field">

                            <label>Número Whatsapp (Com DDD e DDI)</label>
                            <input type="text" placeholder="(Número sem espaços ou traços)" />

                        </div>

                    </b-col>


                    <!--SELETOR DE SETORES ------------------------------------>
                    <b-col class="data-field">

                        <label class="titleSetores">Selecione os setores para o canal </label>
                        <div class="setores">

                            <div v-for="option in options">
                                <input type="checkbox" :id="`setor-${option.value}`" :value="option.value" v-model="selected">
                                <label :for="`setor-${ option.value}`">{{ option.text}}</label>

                            </div>

                        </div>
                    </b-col>



                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO SKYHUB << -------------------- >> -->
                <div v-if="tipo == '16'">

                    <span> conexão chatbot </span>

                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#region CONEXÃO CAPTURA LEAD << -------------------- >> -->
                <div v-if="tipo == '17'">

                    <span> conexão chatbot </span>

                </div>

            <!--#endregion -->
            <!-->
            <!-->

            <!--#endregion -->


            <div class="w-100 text-right mt-4">
                <botao-modal-cancel class="d-inline mr-2" @click="fecharPopup">Cancelar</botao-modal-cancel>
                <botao-modal-ok class="d-inline" @click="fecharPopup">Salvar</botao-modal-ok>
            </div>

        </b-container>
    </b-modal>

   
</template>

<script>

    import InputPadrao from '@/components/inputs/InputPadrao.vue'
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'

    export default {
        name: 'InputSelectPopup',
        components: {
            BotaoModalOk,
            BotaoModalCancel,
            InputPadrao
        },
        data() {
            return {
                tipo: null,
                tiposNomes: [
                    "ChatBot",
                    "WhatsApp Infobip",
                    "Email",
                    "Atendimento Ativo",
                    "Facebook",
                    "SMS",
                    "Mailing",
                    "Telefonia Receptiva",
                    "Telegram",
                    "WebWhats",
                    "Instagram",
                    "RCS-Removido",
                    "ML Pergunta",
                    "ML Mensagem",
                    "Shopee",
                    "Whats Business",
                    "SkyHub",
                    "CapturarLead"

                ],

                selected: [], // Must be an array reference!
                options: [
                    { text: 'Setor 1', value: 'setor1' },
                    { text: 'Setor 2', value: 'setor2' },
                    { text: 'Financeiro', value: 'financeiro' },
                    { text: 'Comercial', value: 'comercial' }
                ]
            };
        },

        methods:{
            abrirPopup(tipo) {
                this.tipo = tipo;
                this.$bvModal.show("NovaConexaoPopup");
            },

            fecharPopup: function () {
                this.$refs['selectPopup'].hide();
            },



        }

    }
</script>

<style scoped>

    .setores {
        background-color: #F2F2F2;
        border: 1px #CCCCCC solid;

    }

        .setores input[type=checkbox] {
            display: none;
            
        }

        .setores label {
            font-size: 12px;
            cursor: pointer;
            padding: 5px;
            width: 100%;
            opacity: 0.5;
            background-color: #F5F2F2;
            margin: 0;
        }

        .setores input[type=checkbox]:hover + label {
        
            opacity:1;
        
        }

        .setores input[type=checkbox]:checked + label {
            background-color: #E8E8E8;
            opacity: 1;
        }

    .data-field {
        color: #737373;
    }

    input[type=text] {
        opacity:0.5;
        background-color: #F5F2F2;
    }    
    
    input[type=text]:focus {
        opacity:1;
        background-color: #F5F2F2;
    }

    input[type=text]:checked {
        background-color: #F2F2F2;
    }

    .specialLabel {
        color: #737373;
        font-size: 12px;
        font-weight: bold;
        max-width: 47%;
        max-width:50%
    }


    .configExternaButtom {
        background-color: #8F8F8F;
        border: 0;
        padding: 5px;
        width: 100%;
        color: #E6E6E6;
        margin-top: 15px;
        margin-bottom:30px;
        font-size: 14px;
    }

    .tituloDoCampo{

        font-size: 19px;
        margin-bottom: 20px;
        margin-top: 20px;

    }


</style>
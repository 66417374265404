<!-- 1 -->
<!--TEMPLATE =====================================================-->

<template>

    <div>

        <Breadcrumb titulo="CANAL" backgroundColor="#8DDFFD" />

        <div class="Main-Container">

            <div class="Items-Container">

                <!--BARRA DE PESQUISA --------------------------->

                <div id="searchBar"class="mr-auto d-flex">
                    <input type="text" class="pesquisaCanais" v-model="searchTerm" placeholder="Buscar Canal" >
                    <i id="lupaSearch" class="fas fa-search" @click=""></i>
                </div>
                <!----------------------------------------------->
                <!-- ROW DETAILS TABLE ----------------------------------------------------------------------->

                <div class="TabelaDeCanais">
                    <b-table :items="tiposCanalFiltrados" :fields="fields" striped responsive="sm" class="B-Table">

                        <template #head(Canal)="row" class="TituloRowTable">
                            <span class="TituloRowTable">Canais</span>

                        </template>
                        <template #cell(Canal)="row">

                            <i id="Setas" @click="expandirCanais(row.toggleDetails, row.item.Tipo,)" :class="`fas fa-angle-${row.detailsShowing ? 'down' : 'right'} cursor-pointer`"></i>
                            <i @click="row.toggleDetails" style="cursor:pointer" class="NomeCanal">{{row.item.NomeCanal}}</i>

                        </template>




                        <template #row-details="row">
                            <!--Card de dentro do canal ativo------------  || @click="novaConexaoPopup"--------------------------->

                            <b-button id="conexaoButtom" class="box-cliente rounded-0" @click="novaConexaoPopup(row.item.Tipo)">Nova Conexão</b-button>

                            <b-card>

                                <b-table striped hover :items="row.item.canais" :fields="colunasCanal">

                                    <!-- Botoes edição ----------------------------------------------------------------------->


                                    <template #cell(status)="row">

                                        <i v-if="row.item.status" class="fas fa-circle text-success" style="font-size:12px; cursor:default"></i>
                                        <i v-else class="fas fa-circle text-danger" style="font-size: 12px; cursor: default"></i>

                                        <span v-if="row.item.status" style="color: forestgreen; cursor: default"> Online</span>
                                        <span v-else="row.item.status" style="color: red; cursor: default"> Offline</span>

                                    </template>


                                    <template class="botoesEdicao" #cell(botoesEdicao)="editCanais">
                                        <div class="w-100 text-right">

                                            <i title="Editar" class="fas fa-pen mx-2" @click="novaConexaoPopup(row.item.Tipo)"></i>
                                            <i title="Remover" class="fas fa-trash-alt mx-2" @click="$emit('click')"></i>

                                        </div>
                                    </template>

                                </b-table>

                                <input-select-popup ref="selectPopup" @dadoSelecionado="salvarDadoSelecionado" />
                                
    

                            </b-card>
                            <!----------------------------------------------------------------------->

                        </template>




                    </b-table>

                </div><!------------------------------------------------------------------------->

            </div>

        </div>

        <NovaConexaoPopup ref="novaConexao" :tiposCanais="listaTiposCanaisHabilitados" />

    </div>

</template>

<!--==============================================================-->

<!---->
<!---->
<!---->
<!---->

<!--SCRIPT =======================================================-->

<script>

    import { mapGetters, mapActions } from 'vuex'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'

    import InputSelectPopup from '@/components/canais/InputSelectPopup.vue'
    import NovaConexaoPopup from '@/components/canais_NovaTela/NovaConexaoPopup.vue'

    import axios from "axios";

    export default {

        components: {
            Breadcrumb,

            InputSelectPopup,
            NovaConexaoPopup
        },

        // DADOS MOCKADOS DA TABELA ====================================
        data() {

            return {
                fields: ['Canal'],
                tiposCanal: [],
                colunasCanal: ['Nome', { key: "Conexao", label: "Conexão" }, 'Status', 'Setor', {key: "botoesEdicao", label:""}],


                textoPesquisa: '',
                dadosCanal: '',
                searchTerm: null
            }
        },


        computed: {

            tiposCanalFiltrados() {
                if (!this.searchTerm?.trim()) return this.tiposCanal;
                return this.tiposCanal.filter(tipo => {
                    if (tipo.NomeCanal?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.searchTerm.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())) return tipo;
                });
            }
        },

        //#region |=============| REQUISIÇÃO DE CANAIS |=========================|
        created() {
            this.buscarSetores();
            axios.get("api/canal/tipos").then(response => {
                this.tiposCanal = response.data.map(item => ({
                    Tipo: item.Value,
                    NomeCanal: item.Text,
                    canais: []
                }));


                // DADOS MOCKADOS: EXCLUIR
                {  
                    this.tiposCanal[0].canais = [
                        { Nome: `CHAT`, Conexao: "Chat 1", status: true, Setor: "Gerência" }
                    ];

                    this.tiposCanal[1].canais = [
                        { Nome: `Wpp 1`, Conexao: "14 9999-9999", status: false, Setor: "Marketing" },
                        { Nome: `Wpp 2`, Conexao: "14 8888-8888", status: true, Setor: "Atendimento" }
                    ];

                    this.tiposCanal[2].canais = [
                        { Nome: `Email  1`, Conexao: "zecapagodinho@gmail.com", status: false, Setor: "Marketing" },
                        { Nome: `Email 2`, Conexao: "arnaldosacomani@gmail.com", status: true, Setor: "Atendimento" }
                    ];
                }
            });
        },
        //#endregion

        methods: {
            ...mapActions({
                buscarListaCanais: 'canais/buscaCanais',
                buscarSetores: 'setores/buscaSetores',

            }),


            buscarCanais: function () {
                if (!this.listaCanais) {
                    this.buscarListaCanais();
                }
            },


            setCanal: function (canalId) {
                this.$refs.selectPopup.limparFormulario();
                if (canalId) {
                    let canalSelecionado = this.listaCanais.find(x => x.Id === canalId);
                    this.$refs.selectPopup.editarCanal(canalSelecionado);
                }
                this.$refs.selectPopup.showModal();
            },

            novaConexaoPopup: function (tipo) {
                this.$refs.novaConexao.abrirPopup(tipo);
                
                return;

 
            },


            removerCanal: function (canalId) {
                const mensagem = 'Deseja mesmo excluir esse canal?'
                this.$bvModal.msgBoxConfirm(mensagem, {
                    title: 'Exclusão',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        this.$store.dispatch(`canais/remover`, canalId)
                    }
                })
            },


            pesquisarCanal: function (texto) {
                this.textoPesquisa = texto.toLowerCase();
            },


            removeTagsHtml: function (textoHtml) {
                let doc = new DOMParser().parseFromString(textoHtml, 'text/html');
                return doc.body.textContent || "";
            },


            salvarDadoSelecionado: function ($event) {
                if ($event.tipoSelect === 'setores') {
                    this.$store.dispatch('canais/putCanalSetor', { 'id': $event.canalId, 'setorId': $event.selected })
                }
            },


            identificarCanalTipo: function (valorTipo) {
                if (this.listaTiposCanaisHabilitados) {
                    return this.listaTiposCanaisHabilitados.find(x => x.Value === Number(valorTipo))?.Text
                }

                return ''
            },

            identificarCanalTipo: function (valorTipo) {
                if (this.listaTiposCanaisHabilitados) {
                    return this.listaTiposCanaisHabilitados.find(x => x.Value === Number(valorTipo))?.Text
                }

                return ''
            },

            expandirCanais(toggleDetaisFn, tipo) {
                if (typeof toggleDetaisFn == "function") toggleDetaisFn();
                let tipoCanal = this.tiposCanal.find(a => a.Tipo == tipo);


                /*axios.get("api/canal/Consulta-TipoCanal", {
                    params: {
                        tipo: tipo
                    }
                }).then(response => {
                    tipoCanal.canais = response.data.map(obj => ({
                        Id: obj.Id,
                        Nome: obj.Nome,
                        Tipo: (obj.Tipo || obj.Tipo === 0) ? this.identificarCanalTipo(obj.Tipo) : '',
                        Setor: obj.Setor ? obj.Setor.Nome : '',
                        SetorId: obj.Setor ? obj.Setor.Id : '',
                        Alteracao: obj.AlteracaoFormatada,
                        Inclusao: obj.InclusaoFormatada,
                        Horario: obj.ForaDoHorario
                    }));
                })*/
            }
        },


    }


</script>

<!--=============================================================-->

<!---->
<!---->
<!---->
<!---->

<!--STYLE =======================================================-->

<style scoped>

    .Main-Container {
        padding: 10px;
        color: #757575;
    }


    .Items-Container {
        padding: 10px;
        height: 100%;
        background-color: var(--cinza-3);
    }

    #Setas {
        font-size: 20px;
        color: #717373;
        background-color: none;
        border: hidden;
    }


    .Botao_Seta {
        border: hidden;
        background-color: transparent;
        margin-right: 20px;
    }


    .TabelaDeCanais {
        margin: 10px auto;
        padding: 10px;
        padding-top: 0;
        background-color: #FDFDFD;
    }

    .table th, .table td {
        color: #787878;
        vertical-align: top;
    }


    .TituloRowTable {
        color: #787878;
        margin-left: 40px;
        font-size: 15px;
    }


    .NomeCanal {
        color: #9A9A9A;
        font-style: normal;
        margin-left: 30px;
    }


    .rowCard {
        max-height: 100px;
    }

    #conexaoButtom {
        margin-left: 1220px;
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .pesquisaCanais {
        width: 400px !important;
        border: none;
        opacity: 0.7;
    }

        .pesquisaCanais:focus {
            opacity: 1;
            border-bottom: 0.5px #123461 solid;
        }

    #searchBar {
        margin: 0 auto;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    #lupaSearch {
        color: white;
        background-color: #123461;
        padding: 9px;
        padding-left: 15px;
        padding-right: 15px;
    }

    i {
        cursor: pointer;
    }

    i.fas {
        font-size: .8rem;
    }


</style>

<!--=============================================================-->